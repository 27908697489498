* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
p{
    font-size: calc(15px + 0.390625vw);
}

body {
  color: #fff;
  text-align: center;

}
#particles-js {
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: -1;
}

.react--content {
  padding-bottom: 5em;
  background-color: #000;
  color: #fff;
  text-align: center;
}

.section--bg {
  margin-bottom: 3px;
}

.bg--title {
  width: 100%;
}

a:hover {
  color: red;
}

.text--wallet {
  color: #ff0240;
  animation-name: color--burger--text;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

.btn--conect {
  text-align: center;
}

.btn-primary {
  min-width: 30%;
  font-family: 'Montserrat';
  color: white;
  padding: 7px;
  border-radius: 5px;
  animation-name: color--burger;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

.btn--landing.btn-primary {
  box-shadow: none;
  transition: 1s;
}

.btn--landing:hover {
  box-shadow: 0 0 20px 2px #e341ad;
  transition: 1s;
}
@keyframes color--burger {
  0% {
    background-color: #ff0420;
  }

  25% {
    background-color: #395ff6;
  }

  50% {
    background-color: #ff0420;
  }

  75% {
    background-color: #395ff6;
  }

  100% {
    background-color: #ff0420;
  }
}

@keyframes color--burger--text {
  0% {
    color: #ff0420;
  }

  25% {
    color: #395ff6;
  }

  50% {
    color: #ff0420;
  }

  75% {
    color: #395ff6;
  }

  100% {
    color: #ff0420;
  }
}
.overview {
  background-color: rgba(153,153,153,0.07);
  color: #fff;
  padding: 8px;
  border-radius: 20px;
  min-height: 3%;
}

input[type="text"] {
  border: #f9f9f9;
  padding: 15px;
  border-radius: 20px;
  background: transparent;
}
